<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <v-container>
    <v-row
      justify="center"
    >
      <v-col
        cols="12"
        lg="7"
        md="9"
      >
        <v-card
          class="mx-auto mt-10"
          max-width="560"
        >
          <auth-component />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

export default {
  name: 'Login',
  components: { AuthComponent: () => import('@components/auth/Login') },
  data: () => ({}),
  watch: {
    authUser: {
      deep: !0,
      immediate: !0,
      handler (v) {
        if (v) {
          this.navigate(this.APP_ROUTES.homePage)
        }
      }
    }
  }
}
</script>
